<template>
  <Popup popupType="tableType" width="940px">
    <div class="section_comm">
      <div class="head_section">
        <h3 class="tit_section">
          {{ dataList.title }}
        </h3>
      </div>
      <div class="body_section">
        <div class="box_detail">
          <div class="inner_detail">
            <div class="detail_contents" :style="detailPaddingBottom">
              <div class="area_contents" v-html="dataList.contents" />
              <div
                v-if="dataList.attachFileList && dataList.attachFileList.length > 0"
                class="area_file"
              >
                <strong class="tit_file">첨부 파일</strong>
                <FileView :noticeSeq="dataList.seq" :dataList="dataList.attachFileList || []" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="group_btn">
      <button type="submit" class="btn_primary btn_medium" @click="onClickClose">확인</button>
    </div>
  </Popup>
</template>

<script>
import Popup from "@/components/layout/popup/Popup";
import FileView from "@/components/common/file/FileView";
import { decodeHTMLEntities } from "@/utils/stringUtils";

export default {
  name: "NoticeDetailPopup",
  components: {
    Popup,
    FileView,
  },
  props: {
    dataList: Object,
  },
  data() {
    return {
      detailPaddingBottom: null,
    };
  },
  computed: {
    termDecoded() {
      const term = this.text;

      if (!term) return "";

      const result = decodeHTMLEntities(document, term);
      return result;
    },
  },
  watch: {
    dataList(newVal, oldVal) {
      if (!newVal || newVal.length === 0) return;

      this.DetailFileAreaSet();
    },
  },
  mounted() {
    this.DetailFileAreaSet();
  },
  methods: {
    DetailFileAreaSet() {
      this.$nextTick(function () {
        if (!this.dataList.attachFileList || this.dataList.attachFileList.length < 1) return;
        const $areaDetail = $(".detail_contents");
        const $areaFileHeight = $(".area_file")[0].offsetHeight;

        if ($areaFileHeight && $areaFileHeight > 81)
          this.detailPaddingBottom = `padding-bottom:${$areaFileHeight}px`;
      });
    },
    onClickClose() {
      this.$emit("onClickClose");
    },
    // onClickConfirm() {
    //   this.$emit( 'onClickConfirm' );
    // }
  },
};
</script>
<style scoped>
.body_section .box_detail .inner_detail {
  height: 449px;
}
.body_section .box_detail .detail_contents {
  position: relative;
  min-height: 100%;
  box-sizing: border-box;
}
.body_section .box_detail .area_contents {
  padding-bottom: 24px;
}
.body_section .box_detail .area_file {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
